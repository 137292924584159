<template>
  <b-card title="Urgency last 30 days">
    <vue-apex-charts
        type="area"
        height="400"
        stacked="true"
        :options="chartOptions"
        :series="chartSeries"
      />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'
import VueApexCharts from 'vue-apexcharts'


export default {
  components: {
    BCard,
    AppEchartStackedArea,
    VueApexCharts,
  },
  data() {
    return {
      chartSeries:[],
      chartOptions: {
        chart:{stacked:true},
        //colors: ['#008FFB', '#00E396', '#CED4DC'],
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        labels:[1,2,3,4,5,6],
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        xaxis: {
          type: 'datetime'
        },
      },
      series:[],
      // periodStats:{
      //   xAxisData: [1,2,3,4,5,6,7,8,9,10],
      //   series: [
      //     {name: 'test1',
      //       type: 'line',
      //       stack: 'Total',
      //       showSymbol: false,
      //       areaStyle: {color: '#28c76f', opacity: 1},
      //       lineStyle: {
      //         width: 0,
      //       },
      //       data:[]
      //     },
      //     {name: 'test1',
      //       type: 'line',
      //       stack: 'Total',
      //       showSymbol: false,
      //       areaStyle: {color: '#28c76f', opacity: 1},
      //       lineStyle: {
      //         width: 0,
      //       },
      //       data:[]
      //     },
      //     {name: 'test1',
      //       type: 'line',
      //       stack: 'Total',
      //       showSymbol: false,
      //       areaStyle: {color: '#28c76f', opacity: 1},
      //       lineStyle: {
      //         width: 0,
      //       },
      //       data:[]
      //     },

      //   ],
      // },
      stats:[],
      // option: {
      //   xAxisData: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12'],
      //   series: [
      //     {
      //       name: 'Routine',
      //       type: 'line',
      //       stack: 'Total',
      //       showSymbol: false,
      //       areaStyle: {color: '#28c76f', opacity: 1},
      //       lineStyle: {
      //         width: 0,
      //       },
      //       data: [750, 232, 601, 154, 390, 330, 410, 510, 420, 320, 580, 690, 650, 800],
      //     },          
      //     {
      //       name: 'Urgent',
      //       type: 'line',
      //       stack: 'Total',
      //       showSymbol: false,
      //       areaStyle: {color: '#ea5455', opacity: 1},
      //       lineStyle: {
      //         width: 0,
      //       },
      //       data: [120, 182, 91, 134, 190, 130, 150, 100, 180, 163, 180, 100, 160, 140],
      //     }
      //   ],
      // },
    }
  },
  methods: {
    async loadStats() {
        var res = await this.callApi('get', '/results/periodStats');
        if (!res.data.result) return;
        this.stats = res.data.result;
        //this.periodStats.xAxisData = ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12']
        //this.periodStats.xAxisData = this.stats.labels
        //console.log(this.periodStats.xAxisData)

        var k=0;
        //this.chartOptions.colors = [];

        this.stats.series.forEach(serie=>{
            this.chartOptions.colors[k]=serie.color;
            this.chartSeries.push({
                name:serie.name,
                // type: 'line',
                // stack: 'Total',
                // showSymbol: false,
                // areaStyle: {color: serie.color, opacity: 1},
                // lineStyle: {width: 0,},
                data: serie.data
            })
            k++
        })
        //console.log(this.periodStats.series)
        // this.periodStats.series[0]=
        //   {
        //     name: 'Routine',
        //     type: 'line',
        //     stack: 'Total',
        //     showSymbol: false,
        //     areaStyle: {color: '#28c76f', opacity: 1},
        //     lineStyle: {width: 0,},
        //     data: [750, 232, 601, 154, 390, 330, 410, 510, 420, 320, 580, 690, 650, 800],
        //   };
                    
        //   this.periodStats.series[1]={
        //     name: 'Urgent',
        //     type: 'line',
        //     stack: 'Total',
        //     showSymbol: false,
        //     areaStyle: {color: '#ea5455', opacity: 1},
        //     lineStyle: {
        //       width: 0,
        //     },
        //     data: [120, 182, 91, 134, 190, 130, 150, 100, 180, 163, 180, 100, 160, 140],
        //   }

          //console.log(this.$refs.resultsChart.$children[0])
          // this.$refs.resultsChart.$children[0].refresh()
          // this.$refs.resultsChart.$children[0].resize()
        
      }
    
  },
  mounted() {
    this.loadStats();
  }
}
</script>

<style>
.echarts{width:100% !important;}
</style>
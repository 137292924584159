<template>
  <b-card title="Results">
    <div class="pie-text text-center">
      <h2 class="font-weight-bolder">
        {{totalStatsCount}}
      </h2>
      <span class="font-weight-bold">Total</span>
    </div>

    <!-- echart -->
    <app-echart-doughnut :series="chartTotalStats" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default {
  components: {
    BCard,
    AppEchartDoughnut,
  },
  computed: {
    totalStatsCount() {
        var c = 0;
        if (this.totalStats) this.totalStats.forEach(stat=>c+=stat.value)
        return c;
    }
  },
  data() {
    return {
      chartTotalStats:[],
      totalStats: [],
      // series: [
      //   {
      //     name: 'Urgency',
      //     type: 'pie',
      //     radius: ['50%', '70%'],
      //     avoidLabelOverlap: false,
      //     label: {
      //       show: false,
      //     },
      //     labelLine: {
      //       show: false,
      //     },
      //     color: ['#28c76f', '#ea5455'],
      //     data: [
      //       { value: 335, name: 'Routine' },
      //       { value: 400, name: 'Urgent' },
      //     ]
      //   },
      // ],
    }
  },
  methods: {
    async loadTotalStats() {
      var res = await this.callApi('get', '/results/totalStats');
      if (!res.data.result) return;
      this.totalStats = res.data.result
      this.chartTotalStats.push({
          name: 'Urgency',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          color: res.data.result.map(stat=>stat.color),
          data:res.data.result
          
        })
    }
  },
  mounted() {
    this.loadTotalStats();
  }
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
</style>

<template>
  <section id="dashboard">
    <b-row>
      <b-col md="6">
        <dashboard-results-urgency />
      </b-col>
      <b-col md="6">
        <dashboard-results-chart md="6" />
      </b-col>
    </b-row>

    <!-- Company Table Card -->
    <b-card no-body>
      <h4 class="mt-2 mr-2 mb-2 ml-2">Latest results</h4>
      <results-table :table-data="data.results" />
    </b-card>
    <!--/ Company Table Card -->
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { BCard, BCardText } from "bootstrap-vue";
import ResultsTable from "../components/ResultsTable.vue";
import DashboardResultsChart from "./DashboardResultsChart.vue";
import DashboardResultsUrgency from "./DashboardResultsUrgency.vue";


export default {
  data() {
    return {
      data: {
        results:[]
        // checkResults: [
        //   {
        //     id: 1,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //     avatarImg: require("@/assets/images/icons/toolbox.svg"),
        //     title: "Dixons",
        //     subtitle: "meguc@ruj.io",
        //     avatarIcon: "MonitorIcon",
        //     avatarColor: "light-primary",
        //     avatarTitle: "Technology",
        //     viewTitle: "23.4k",
        //     viewsub: "in 24 hours",
        //     revenue: "891.2",
        //     sales: "68",
        //     loss: true,
        //   },
        //   {
        //     id: 2,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Urgent",
        //     urgencyStatus: "danger",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //     avatarImg: require("@/assets/images/icons/parachute.svg"),
        //     title: "Motels",
        //     subtitle: "vecav@hodzi.co.uk",
        //     avatarIcon: "CoffeeIcon",
        //     avatarColor: "light-success",
        //     avatarTitle: "Grocery",
        //     viewTitle: "78k",
        //     viewsub: "in 2 days",
        //     revenue: "668.51",
        //     sales: "97",
        //     loss: false,
        //   },
        //   {
        //     id: 3,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: null,
        //     avatarImg: require("@/assets/images/icons/brush.svg"),
        //     title: "Zipcar",
        //     subtitle: "davcilse@is.gov",
        //     avatarIcon: "WatchIcon",
        //     avatarColor: "light-warning",
        //     avatarTitle: "Fashion",
        //     viewTitle: "162",
        //     viewsub: "in 5 days",
        //     revenue: "522.29",
        //     sales: "62",
        //     loss: false,
        //   },
        //   {
        //     id: 4,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Urgent",
        //     urgencyStatus: "warning",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //     avatarImg: require("@/assets/images/icons/star.svg"),
        //     title: "Owning",
        //     subtitle: "us@cuhil.gov",
        //     avatarIcon: "MonitorIcon",
        //     avatarColor: "light-primary",
        //     avatarTitle: "Technology",
        //     viewTitle: "214",
        //     viewsub: "in 24 hours",
        //     revenue: "291.01",
        //     sales: "88",
        //     loss: false,
        //   },
        //   {
        //     id: 5,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Urgent",
        //     urgencyStatus: "danger",
        //     advice: "Contact GP",
        //     exerciseId: null,
        //     avatarImg: require("@/assets/images/icons/book.svg"),
        //     title: "Cafés",
        //     subtitle: "pudais@jife.com",
        //     avatarIcon: "CoffeeIcon",
        //     avatarColor: "light-success",
        //     avatarTitle: "Grocery",
        //     viewTitle: "208",
        //     viewsub: "in 1 week",
        //     revenue: "783.93",
        //     sales: "16",
        //     loss: true,
        //   },
        //   {
        //     id: 6,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: null,
        //     avatarImg: require("@/assets/images/icons/rocket.svg"),
        //     title: "Kmart",
        //     subtitle: "bipri@cawiw.com",
        //     avatarIcon: "WatchIcon",
        //     avatarColor: "light-warning",
        //     avatarTitle: "Fashion",
        //     viewTitle: "990",
        //     viewsub: "in 1 month",
        //     revenue: "780.05",
        //     sales: "78",
        //     loss: false,
        //   },
        //   {
        //     id: 7,
        //     date: "2022-08-03",
        //     time: "16:45",
        //     urgency: "Routine",
        //     urgencyStatus: "success",
        //     advice: "Contact GP",
        //     exerciseId: 123,
        //     avatarImg: require("@/assets/images/icons/speaker.svg"),
        //     title: "Payers",
        //     subtitle: "luk@izug.io",
        //     avatarIcon: "WatchIcon",
        //     avatarColor: "light-warning",
        //     avatarTitle: "Fashion",
        //     viewTitle: "12.9k",
        //     viewsub: "in 12 hours",
        //     revenue: "531.49",
        //     sales: "42",
        //     loss: false,
        //   },
        // ],
      },
    };
  },
  methods: {
    async loadResults() {
      //console.log('LOAD RESULTS2');
			const res = await this.callApi("get", "/results/lastResults");
			this.data.results = res.data.result;
      //console.log('HIER: '+JSON.stringify(this.data.results));
      //console.log(this.hasPermission("algorithm"))
		},
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    ResultsTable,
    DashboardResultsChart,
    DashboardResultsUrgency,
  },
  mounted() {
    this.loadResults()
  }
};
</script>